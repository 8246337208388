import { USER_ACTION_TYPES } from './actionTypes';
import {
    setCookie,
    deleteCookie,
    DOMAIN_COOKIE_NAME,
    DAARA_COOKIE_NAME,
    DAARA_TOKEN_PREFIX,
    DARA_REFRESH_TOKEN_NAME
} from '../services/utilityService';
import userService from '../services/userService';
import { sessionService } from 'redux-react-session';
import Cookies from 'js-cookie';

import { features } from '../services/daara-config.json';

//////////////////// ACTIONS //////////////////////////

const login = () => {
    return {
        type: USER_ACTION_TYPES.LOGIN
    };
};

const loginSuccessful = (client) => {
    return {
        type: USER_ACTION_TYPES.LOGIN_SUCCESS,
        data: client
    };
};

const loginFailed = (error) => {
    return {
        type: USER_ACTION_TYPES.LOGIN_FAIL,
        data: error
    };
};

const updateProfileSuccessful = () => {
    return {
        type: USER_ACTION_TYPES.UPDATE_PROFILE_SUCCESS
    };
};

const setLoading = () => {
    return {
        type: USER_ACTION_TYPES.START_LOADING
    };
};

const stopLoading = () => {
    return {
        type: USER_ACTION_TYPES.STOP_LOADING
    };
};

export const forgotPassword = () => {
    return {
        type: USER_ACTION_TYPES.RESET_PASSWORD
    };
};

export const recoverPasswordSuccessful = (message) => {
    return {
        type: USER_ACTION_TYPES.RESET_PASSWORD_SUCCESS,
        data: message
    };
};

export const recoverPasswordFailed = (message) => {
    return {
        type: USER_ACTION_TYPES.RESET_PASSWORD_FAIL,
        data: message
    };
};

//////////////////// THUNKS //////////////////////////////

export const setUserLoading = (isLoading) => {
    return (dispatch) => {
        if (isLoading) {
            dispatch(setLoading());
        } else {
            dispatch(stopLoading());
        }
    };
};

export const authUser = (accessToken) => {
    return (dispatch) => {
        userService.auth(accessToken).then((user) => {
            refreshUser(dispatch, user);
        });
    };
};

export const loginUser = (id, password, rememberMe, history) => {
    return (dispatch) => {
        dispatch(login());
        userService
            .login(id, password)
            .then((user) => {
                console.log(user);
                saveSession(dispatch, user, history, rememberMe);
            })
            .catch((err) => {
                console.log(err);
                dispatch(loginFailed({ message: err.message, code: err.code }));
            });
    };
};

export const loginWithAccessToken = (accessToken, to) => {
    return (dispatch) => {
        dispatch(setUserLoading(true));
        userService
            .auth(accessToken)
            .then((user) => {
                saveSession(dispatch, user, to);
            })
            .catch((err) => {
                dispatch(loginFailed({ message: err.message, code: err.code }));
            });
    };
};

export const resetPassword = (values) => {
    //try getting fcmToken if permission already granted
    return (dispatch) => {
        dispatch(forgotPassword());
        userService.resetPassword(values).then(
            (data) => {
                dispatch(recoverPasswordSuccessful(data.message));
            },
            (err) => {
                const { data, statusText } = err.response || {
                    status: -1,
                    statusText: 'Unknown Error',
                    data: {}
                };
                dispatch(recoverPasswordFailed({ message: data.message || statusText }));
            }
        );
    };
};

const refreshUser = (dispatch, user) => {
    sessionService.loadUser().then(() => {
        sessionService.saveUser(user).then(() => {
            dispatch(updateProfileSuccessful());
        });
    });
};

const getDomain = () => {
    return process.env[`REACT_APP_DOMAIN`];
};

const saveSession = (dispatch, user, history, rememberMe) => {
    //set accesstoken in cookie

    const expiryDays = rememberMe ? 30 : 2;
    setCookie(DOMAIN_COOKIE_NAME, user.credentials.accessToken, expiryDays, getDomain());
    if (features.account_service.auth) {
        localStorage.setItem(
            `${DAARA_COOKIE_NAME}_${DAARA_TOKEN_PREFIX}`,
            user.credentials.daaraToken
        );
        setCookie(DAARA_COOKIE_NAME, user.credentials.daaraToken, expiryDays, getDomain());
        Cookies.set(DARA_REFRESH_TOKEN_NAME, user.credentials.refreshToken);
    }
    sessionService.saveSession(user.credentials.accessToken).then(() => {
        sessionService.saveUser(user).then(() => {
            dispatch(loginSuccessful(user, history));
        });
    });
};

const logoutUserFromDaaraService = () => {
    return sessionService
        .loadUser()
        .then((user) => {
            fetch(`${process.env.REACT_APP_DAARA_ACCOUNT_SERVICE_URL}/logOut`, {
                method: 'GET',
                headers: new Headers({
                    Authorization: `Bearer ${user.credentials.daaraToken}`,
                    'Content-Type': 'application/json'
                })
            })
                .then((response) => {})
                .catch((error) => {});
        })
        .catch((error) => {});
};

export const logoutUser = async (from, history) => {
    await logoutUserFromDaaraService();
    return sessionService
        .deleteUser()
        .then(() => {
            sessionService.deleteSession().then(() => {
                if (features.account_service.auth) {
                    localStorage.removeItem(`${DAARA_COOKIE_NAME}_${DAARA_TOKEN_PREFIX}`);
                }
                deleteCookie(DOMAIN_COOKIE_NAME, getDomain());
                deleteCookie(DAARA_COOKIE_NAME, getDomain());
                let dest = `/login${from ? `?r=${from}` : ``}`;
                history.push(dest);
            });
        })
        .catch((err) => {
            throw err;
        });
};
